<template>
  <KTPortlet title="Tạo mới người dùng" headSize="md" class="create-user">
    <template v-slot:body>
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="avatar-container" label="Hình đại diện">
          <div class="photo-container" @click="$refs.avatar.click()">
            <img :src="previewImage" alt="avatar" />
          </div>
          <input
            ref="avatar"
            type="file"
            accept="image/*"
            @change="getImageData"
          />
        </b-form-group>

        <div class="row">
          <b-form-group label="Tên" class="col-7">
            <b-form-input
              type="text"
              required
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Tên viết tắt" class="col-5">
            <b-form-input type="text" v-model="form.shortName"></b-form-input>
          </b-form-group>
        </div>

        <div class="row">
          <b-form-group
            class="col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Ngày sinh"
          >
            <b-input-group class="mb-3">
              <b-form-input
                id="dob-input"
                v-model="dobFormat"
                type="text"
                placeholder="DD/MM/YYYY"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="form.dob"
                  v-bind="label_vn"
                  :max="minDate"
                  button-only
                  required
                  right
                  aria-controls="dob-input"
                  locale="vie"
                  @context="onContext"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            class="col-sm-12 col-md-9 col-lg-9 col-xl-9"
            label="Địa chỉ"
          >
            <b-form-input
              type="text"
              required
              v-model="form.address"
            ></b-form-input>
          </b-form-group>
        </div>

        <b-form-group label="Số điện thoại">
          <b-form-input
            type="text"
            required
            v-model="form.phoneNumber"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email">
          <b-form-input
            type="email"
            required
            v-model="form.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Tiệm">
          <v-select
            :options="allOutlets"
            multiple
            label="name"
            v-model="outLetsSelected"
          >
            <template v-slot:no-options="{ allOutlets }">
              <em v-if="!allOutlets">Không tìm thấy tiệm nào</em>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group label="Quyền">
          <b-form-select v-model="form.role" :options="roles"></b-form-select>
        </b-form-group>

        <b-form-group label="Lương">
          <b-form-input
            v-model="form.salary"
            type="number"
            required
            min="0"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Phần trăm hoa hồng">
          <b-form-input
            v-model="form.commissionPercent"
            type="number"
            min="0"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">Hoàn thành</b-button>
      </b-form>
    </template>
  </KTPortlet>
</template>

<style lang="scss">
@import "./User.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { mapGetters, createLogger } from "vuex";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import { createUser } from "@/api/user";
import Loader from "@/common/loader";
import { LABEL_VN } from "./../../constans";
import moment from "moment";

export default {
  name: "UserCreate",
  components: {
    KTPortlet,
    vSelect,
  },
  data() {
    return {
      states: [
        {
          value: true,
          text: "Kích hoạt",
        },
        {
          value: false,
          text: "Chưa kích hoạt",
        },
      ],
      roles: [
        { value: 1, text: "Kỹ thuật viên" },
        { value: 2, text: "Thu ngân" },
        { value: 3, text: "Quản lý tiệm" },
        { value: 4, text: "Chủ tiệm" },
      ],
      form: {
        name: "",
        address: "",
        phoneNumber: "",
        email: "",
        role: 1,
        outletId: [],
        salary: 0,
        commissionPercent: 0,
        shortName: "",
        dob: "",
      },
      outLetsSelected: [],
      dobFormat: "",
      image: null,
      previewImage: require("../../assets/Rectangle.png"),
      label_vn: LABEL_VN,
      minDate: new Date(),
    };
  },

  computed: mapGetters(["allOutlets"]),

  created() {
    this.$store.dispatch("resetListOutlets");
    this.getListOutlet();
  },

  methods: {
    onContext(content) {
      if (content.selectedDate) {
        this.dobFormat = moment(content.selectedDate).format("DD/MM/YYYY");
      }
    },

    onSubmit() {
      const data = JSON.parse(JSON.stringify(this.form));
      this.outLetsSelected.forEach((element) => {
        data.outletId.push(element._id);
      });

      if (this.previewImage) data.avatar = this.previewImage;
      if (!data.shortName) delete data.shortName;

      data.dob = new Date(
        moment(this.dobFormat, "DD/MM/YYYY").format("MM/DD/YYYY")
      );
      if (data.dob == "Invalid Date") {
        return Swal.fire({
          icon: "error",
          text: "Ngày tháng năm sinh không hợp lệ!",
          showConfirmButton: true,
          confirmButtonText: "Đồng ý",
        });
        Loader.close();
      }

      createUser(data)
        .then((res) => {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Tạo người dùng thành công!",
            showConfirmButton: false,
            timer: 3000,
          });

          Swal.fire({
            position: "center",
            icon: "success",
            html: `<div class="info-user">
                        <div class="main">
                            <span class="title">
                            Tên:
                            </span>
                            <span class="content">${res.data.name}</span>
                        </div>
                        <div class="main">
                            <span class="title">
                            Địa chỉ:
                            </span>
                            <span class="content">${res.data.address}</span>
                        </div>
                        <div class="main">
                            <span class="title">
                            Số điện thoại:
                            </span>
                            <span class="content">${res.data.phoneNumber}</span>
                        </div>
                        <div class="main">
                            <span class="title">
                            Email:
                            </span>
                            <span class="content">${res.data.email}</span>
                        </div>
                        <div class="main">
                            <span class="title">
                            Mật khẩu:
                            </span>
                            <span class="content">${res.data.password}</span>
                        </div>
                        </div>`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
          }).then(() => {
            this.$router.push("/user");
          });
        })
        .catch((e) => {
          if (e.errors === "Image too big") {
            Loader.close();
            Swal.fire({
              icon: "error",
              text:
                "Hình đại diện có kích thước quá lớn, hãy dùng hình có kích thước nhỏ hơn!",
              showConfirmButton: true,
              confirmButtonText: "Đồng ý",
            });
          }
          if (e.errors === "Phone number or email is existed!") {
            Loader.close();
            Swal.fire({
              icon: "error",
              text: "Số điện thoại hoặc email đã tồn tại, hãy kiểm tra lại!",
              showConfirmButton: true,
              confirmButtonText: "Đồng ý",
            });
          }
        });
    },

    getListOutlet() {
      this.$store.dispatch("setOutlets", { status: 1 });
    },

    getImageData(evt) {
      const image = evt.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
  },
};
</script>
